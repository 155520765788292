body,
html {
    padding: 0px;
    margin: 0px;

    background-color: black;
}

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


* {
    box-sizing: border-box;
    /* background-color: rgb(209, 250, 255); */

}

/* Add a background to the overall container */
.overall-container {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    padding: 0px;
}

/* Section 1 styling with glass effect */
.parent-container {
    height: 100%;
    width: 100%;

}


.section-1 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}

.section-3 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}

.section-4 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}

.section-5 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}

.section-6 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}

.section-7 {
    /* height: 100%; */
    width: 100%;
    border-radius: 15px;
}




.section-2 {
    height: 100%;
    padding-top: 5%;
    width: 100%;
    border-radius: 15px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    display: flex;
    justify-content: end;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .section-2 .css-dp4t03 {
        width: 100%;
        flex-direction: column;
        justify-content: inherit;
    }

    .section-2 .about-content2 {
        display: none !important;
    }


}